<script>
/**
 * Reservation component
 */
export default {
  props: {
    reservations: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    verifierMulti(item, tournois) {
      let resp = tournois.titre;
      if (item.meta.teams != null) {
        const teams = JSON.parse(item.meta.teams);
        teams.forEach(element => {
          if (JSON.parse(element[0].tournois[0]).id == tournois.id) {
            resp += ' (Multijoueur)'
          }
        });
      }
      return resp;
    },
    showDetail(item) {
      this.$router.push(`/events/reservations/${item.id}/detail`);
    }
  }
};
</script>

<template>
  <div class="table-responsive mb-0">
    <table class="table table-hover table-centered table-nowrap">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Evenement</th>
          <th scope="col">Tournois</th>
          <th scope="col">Montant</th>
          <th v-if="reservations.length > 0 && reservations[0].utilisateur != null" scope="col">Client</th>
          <th v-if="reservations.transaction != null && reservations.transaction.length > 0" scope="col">Etat</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in reservations" :key="data.id">
          <td scope="row">{{data.date | defautTableDateTime2 }}</td>
          <td>
            <div>
              {{data.evenement.titre}}
            </div>
          </td>
          <td>
            <span
              style="margin-left: 3px;"
              v-bind:key="index"
              v-for="(item, index) of data.meta.tournois"
                  class="badge"
                  :class="{
                      'bg-info': true,
                  }">{{ verifierMulti(data, item) }}</span>
            <!-- {{data.meta.tournois.length}} -->
          </td>
          <td>
             <span
                class="badge"
                :class="{
                  'bg-success': true
                }"
              >{{ data.montant | currencyFormat }}
            </span>
            
          </td>
          <td v-if="data.utilisateur != null">
             {{ data.utilisateur.firstname }} {{ data.utilisateur.lastname }}
          </td>
           <td v-if="data.transaction != null">
               <span v-if="data.transaction.length == 0"
                  class="badge"
                  :class="{
                      'bg-warning': true,
                  }"> Aucun paiement</span>
                  <span v-if="data.transaction.length > 0 && data.transaction[0].state"
                  class="badge"
                  :class="{
                      'bg-success': true,
                  }"> Paiement effectué</span>
                  <span v-if="data.transaction.length > 0 && !data.transaction[0].state"
                  class="badge"
                  :class="{
                      'bg-danger': true,
                  }"> Paiement échoué</span>
            </td>
          <td>
            <div>
              <a @click="showDetail(data)" style="cursor: pointer;" class="btn btn-primary btn-sm">Détail</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>