<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { imageEventBaseUrl, imageTournoisBaseUrl } from '../../../../constants/config';
import Reservation from "@/components/widgets/reservation";

/**
 * Blank page component
 */
export default {
    page: {
        title: "Détail De la transaction",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, Reservation },
    data() {
        return {
            title: "Détail de la transaction",
            transaction: null,
            imageEventBaseUrl,
            imageTournoisBaseUrl,
            showTeam: false,
            teamData: [],
            teamLead: null

        };
    },
    mounted() {
        this.getTransaction();
    },
    methods: {
        async getTransaction() {
        },
        back() {
            this.$router.back();
        },

        async showTeams(item, r) {
            this.teamData = JSON.parse(r.meta.teams);
            this.teamLead = '';
            this.$bvModal.show('modal-standard-teams');
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" />
        <i @click="back()" style="font-size: 28px; cursor: pointer; margin-bottom: 10px;" class="fa fa-arrow-left" st></i>
        <div v-if="transaction != null">
            <div class="row">
                <div class="col-md-12 mb-4">

                    <span class="fs-3 fw-bold">Le {{ transaction.date | defautTableDateTIme }}</span><br><br>
                    <span class="badge fs-2" style="margin-left: 5px; margin-right: 5px;" :class="{ 'bg-primary': true }">
                        {{ transaction.montant | currencyFormat }} </span>

                    <span v-if="transaction.state" class="badge fs-2"
                        :class="{
                            'bg-success': true,
                        }"> Paiement effectué</span>
                    <span v-if="!transaction.state" class="badge fs-2"
                        :class="{
                            'bg-danger': true,
                        }"> Paiement échoué</span>
                    <!-- <br><br> -->
                    <span class="badge fs-2" style="margin-left: 5px; margin-right: 5px;" :class="{ 'bg-primary': true }">
                            Réf: {{ transaction.reference }} </span>
                    <span class="badge fs-2" style="margin-left: 5px; margin-right: 5px;" :class="{ 'bg-info': true }">
                        {{ transaction.operateur }} </span> 
                    <span class="badge fs-2" style="margin-left: 5px; margin-right: 5px;" :class="{ 'bg-info': true }">
                        {{ transaction.tel }} </span>     
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3">
                                <b-tab active class="border-0">
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <!-- <i class="fas fa-home"></i> -->
                                            Informations du joueur
                                        </span>
                                        <span class="d-none d-sm-inline-block">Informations du joueur</span>
                                    </template>
                                    <div class="row">
                                        <div class="col-md-4 p-4">
                                            Nom du joueur: <span class="fs-4" style="margin-left: 5px;"> {{
                                                transaction.reservation.meta.player }}</span>
                                        </div>
                                        <div class="col-md-4 p-4">
                                            Age: <span class="fs-4" style="margin-left: 5px;"> {{ transaction.reservation.meta.age
                                            }}</span>
                                        </div>
                                        <div class="col-md-4 p-4">
                                            Profession: <span class="fs-4" style="margin-left: 5px;"> {{
                                                transaction.reservation.meta.work }}</span>
                                        </div>
                                        <div class="col-md-4 p-4">
                                            T-Shirt: <span class="fs-4" style="margin-left: 5px;"> {{
                                                transaction.reservation.meta.tshirt }}</span>
                                        </div>
                                        <div class="col-md-4 p-4">
                                            Pointure: <span class="fs-4" style="margin-left: 5px;"> {{
                                                transaction.reservation.meta.pointure }}</span>
                                        </div>
                                        <div class="col-md-4 p-4">
                                            Canal Plus: <span class="fs-4" style="margin-left: 5px;"> {{
                                                transaction.reservation.meta.canalplus }}</span>
                                        </div>
                                        <div class="col-md-4 p-4">
                                            Canal Box: <span class="fs-4" style="margin-left: 5px;"> {{
                                                transaction.reservation.meta.canalbox }}</span>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab>
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <!-- <i class="far fa-user"></i> -->
                                            Tournois
                                        </span>
                                        <span class="d-none d-sm-inline-block">Tournois <span class="badge fs-6"
                                                :class="{ 'bg-primary': true }"> {{ transaction.reservation.meta.tournois.length }}
                                            </span> </span>
                                    </template>
                                    <div v-if="transaction.reservation != null && showTeam == false" class="row"
                                        style="margin-bottom: 40px;">
                                        <div class="row">
                                            <div v-bind:key="index" v-for="(item, index) in transaction.reservation.meta.tournois"
                                                class="col-md-4 pb-4">
                                                <div class="card bg-primary">
                                                    <img :src="`${imageTournoisBaseUrl}/${item.image}`" class="card-img-top"
                                                        alt="...">
                                                    <div class="card-body">
                                                        <p class="card-text fw-bold text-white">{{ item.titre }}</p>
                                                        <p class="text-white">Le {{ item.debut | defautTableDateTime2 }}</p>
                                                        <p v-if="item.isMultiple"><span
                                                                class="badge bg-danger fw-bold fs-6">{{
                                                                    transaction.reservation.meta.teams != null &&
                                                                    JSON.parse(transaction.reservation.meta.teams).length > 0 ? "Mode Multi"
                                                                    : "Mode Solo" }}</span></p>
                                                        <p class="text-white btn btn-purple"
                                                            v-if="transaction.reservation.meta.teams != null && JSON.parse(transaction.reservation.meta.teams).length > 0"
                                                            style="cursor: pointer;" @click="showTeams(item, transaction.reservation)"> Voir l'équipe
                                                            sur ce tournoi</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab>
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <!-- <i class="far fa-envelope"></i> -->
                                            Réservation
                                        </span>
                                        <span class="d-none d-sm-inline-block">Réservation</span>

                                    </template>
                                    <div class="row">
                                        <div class="card">
                                            <div class="card-body">
                                                <Reservation :reservations="[transaction.reservation]" :showDetail="false" />
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab>
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <!-- <i class="fas fa-cog"></i> -->
                                            Utilisateur
                                        </span>
                                        <span class="d-none d-sm-inline-block">Utilisateur</span>
                                    </template>
                                    <div class="row">
                                        <div class="col-md-4 p-4">
                                            Nom: <span class="fs-4">{{ transaction.utilisateur.firstname }}</span>
                                        </div>
                                        <div class="col-md-4 p-4">
                                            Prénoms: <span class="fs-4">{{ transaction.utilisateur.lastname }}</span>
                                        </div>
                                        <div class="col-md-4 p-4">
                                            Email: <span class="fs-4">{{ transaction.utilisateur.email }}</span>
                                        </div>
                                        <div class="col-md-4 p-4">
                                            Tel: <span class="fs-4">{{ transaction.utilisateur.tel }}</span>
                                        </div>
                                        <div class="col-md-4 p-4">
                                            Adresse: <span class="fs-4">{{ transaction.utilisateur.adresse }}</span>
                                        </div>

                                    </div>
                                </b-tab>
                                <b-tab>
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <!-- <i class="fas fa-cog"></i> -->
                                            Evènement
                                        </span>
                                        <span class="d-none d-sm-inline-block">Evènements</span>
                                    </template>
                                    <div class="row" style="text-align: center;">

                                        <div class="card bg-success" style=" text-align: center;">
                                            <img :src="`${imageEventBaseUrl}/${transaction.reservation.evenement.image}`"
                                                class="card-img-top" :alt="transaction.reservation.evenement.titre">
                                            <div class="card-body">
                                                <p class="card-text fw-bold text-white">{{ transaction.reservation.evenement.titre }}
                                                </p>
                                            <p class="text-white">Du {{ transaction.reservation.evenement.debut | defautTableDate }}
                                                au {{ transaction.reservation.evenement.fin | defautTableDate }}</p>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <!-- end card-body -->
                </div>
            </div>
        </div>

         <b-modal id="modal-standard-teams" centered hide-footer size="lg" title-class="font-18">
                <template #modal-header="{ title }">
                    <div class="title fs-3 fw-bold">
                     Equipe
                    </div>
                </template>
               <div class="table-responsive mb-0">
              <table class="table table-hover table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Nom Joueur</th>
                    <th scope="col">Téléphone</th>
                    <th scope="col">Email</th>
                    <th scope="col">TShirt</th>
                    <th scope="col">Pointure</th>
                    <th scope="col">Age</th>
              
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in teamData[0]" :key="data.id">
                    <td scope="row">{{ data.player }}</td>
                    <td scope="row">{{ data.phone }}</td>
                    <td scope="row">{{ data.email }}</td>
                    <td scope="row">{{ data.tshirt }}</td>
                    <td scope="row">{{ data.pointure }}</td>
                    <td scope="row">{{ data.age }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            </b-modal> 
    </div>
</Layout></template>